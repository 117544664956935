export const siteName = "Özpaş Sanal Market";
export const siteNameUpper = "ÖZPAŞ SANAL MARKET";

export const siteMailList = [
  {
    mail: "bilgi@ozpasmarket.com",
    subject: "Bize Mail Bırakın",
  },
];

export const sitePhoneList = [
  { phone: "0 (264) 211 31 00", info: "" },


];

export const eklenenMiktarKatsayisi = 0.1;
